import * as m from 'models';
import { DateT, IWhen, Time } from 'components/widgets/time-types';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { WidgetProps } from 'components/widgets';
import { isAddress, isUrl } from 'shared/shared/utils';
import DateTimeWidget from 'components/widgets/DateTimeWidget';
import IconWithText from 'components/common/IconWithText/IconWithText';
import LocationWidget from 'components/EventCreateUpdateForm/EventCreateUpdateForm/fields/Location/LocationWidget/LocationWidget';
import OptionSwitch from 'components/OptionSwitch/OptionSwitch';
import React, { ChangeEvent, useState } from 'react';
import StreamLinkWidget from 'components/widgets/StreamLinkWidget';
import TimeTitle from 'components/EventCreateUpdateForm/WhenWidget/TimeTitle';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import styles from './WhereWidget.module.scss';

enum WhereType {
  VIRTUAL = 'virtual',
  PHYSICAL = 'physical',
  UNKNOWN = 'unknown',
}

export interface IWhere {
  location: string;
  locationReleaseTimeDelta?: moment.Duration | null;
}

interface Attrs {
  when: IWhen;
}

export const eventToIWhere = (event?: m.Event): IWhere => {
  return {
    location: event?.location ?? '',
    locationReleaseTimeDelta: event?.locationReleaseTimeDelta,
  };
};

const WhereWidget = (props: WidgetProps<IWhere, Attrs>) => {
  const { attrs, onChange, value } = props;
  const { location, locationReleaseTimeDelta } = value;
  const { start, timezone } = attrs!.when!;
  const startTime = start ? moment.tz(start, timezone!) : undefined;

  const currentType = location ? (!isUrl(location) ? WhereType.PHYSICAL : WhereType.VIRTUAL) : WhereType.PHYSICAL;

  const initialVirtualLocation = currentType === WhereType.VIRTUAL ? location : '';
  const initialPhysicalLocation = currentType === WhereType.PHYSICAL ? location : '';
  const initIsScheduled = !!locationReleaseTimeDelta;
  const releaseTime = moment(startTime);

  if (locationReleaseTimeDelta) {
    releaseTime.subtract(locationReleaseTimeDelta);
  } else {
    // Initial default release time is one hour before event start
    releaseTime.subtract(moment.duration(1, 'h'));
  }

  const [type, setType] = useState<WhereType>(currentType);
  const [isScheduled, setIsScheduled] = useState<boolean>(initIsScheduled);
  const [virtualLocation, setVirtualLocation] = useState<string>(initialVirtualLocation);
  const [physicalLocation, setPhysicalLocation] = useState<string>(initialPhysicalLocation);

  function handleTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newType = e.target.value as WhereType;

    if (newType !== type) {
      if (newType === WhereType.PHYSICAL) {
        onChangeLocation(physicalLocation);
      } else {
        onChangeLocation(virtualLocation);
      }
    }
    setType(newType);
  }

  function onChangeLocation(updatedLocation: string) {
    onChange({ location: updatedLocation });
  }

  function calculateDeltaFromReleaseTime(newReleaseTime?: Moment) {
    if (!startTime) {
      return null;
    }

    return moment.duration(Math.abs(moment(newReleaseTime).diff(startTime)));
  }

  function handleReleaseDateChange(newVal: DateT | Time) {
    onChange({
      ...value,
      locationReleaseTimeDelta: calculateDeltaFromReleaseTime(moment(newVal)),
    });
  }

  function handleSwitchReleaseDate(enabled: boolean) {
    setIsScheduled(enabled);
    onChange({
      ...value,
      locationReleaseTimeDelta: enabled ? calculateDeltaFromReleaseTime(releaseTime) : null,
    });
  }

  return (
    <div className={styles.container}>
      <RadioGroup name="type" value={type} onChange={handleTypeChange}>
        <FormControlLabel value="physical" control={<Radio color="primary" />} label="In Person" />
        <FormControlLabel value="virtual" control={<Radio color="primary" />} label="Virtual" />
      </RadioGroup>
      <div
        className={classNames('items-center', styles.form, styles.timeForm, {
          flex: type === WhereType.VIRTUAL,
        })}
      >
        <div className={classNames(styles.section, 'w-full')}>
          <TimeTitle label="Event URL" />
          <StreamLinkWidget
            value={virtualLocation}
            onChange={(v) => {
              setVirtualLocation(v);
              onChangeLocation(v);
            }}
            attrs={Object.assign({}, attrs, {
              virtual: true,
            })}
            disabled={false}
          />
          <div className="hidden">
            <div className="mt-6">
              <TimeTitle label="URL Availability" />
            </div>
            <OptionSwitch
              checked={isScheduled}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleSwitchReleaseDate(e.target.checked);
              }}
              fieldName="schedule"
              title="Schedule"
              helpText="Link will be available from the scheduled time for the participants."
            />
            {isScheduled &&
              (startTime ? (
                <div className={classNames(styles.section, 'mt-6')}>
                  <div className={styles.start}>
                    <DateTimeWidget
                      value={releaseTime.toObject()}
                      disabled={false}
                      onChange={handleReleaseDateChange}
                    />
                  </div>
                </div>
              ) : (
                <IconWithText size="small" className="mt-6 text-sm text-gray-600" icon="no">
                  Set the event start time to enable link release scheduling
                </IconWithText>
              ))}
          </div>
        </div>
      </div>
      <div
        className={classNames('items-center', styles.form, styles.timeForm, {
          flex: type === WhereType.PHYSICAL,
        })}
      >
        <div className={classNames(styles.section, 'w-full')}>
          <LocationWidget
            value={physicalLocation}
            onChange={(v) => {
              setPhysicalLocation(v);
              onChangeLocation(v);
            }}
            attrs={Object.assign({}, attrs, {
              inPerson: true,
            })}
            disabled={false}
          />
        </div>
      </div>
    </div>
  );
};

export default WhereWidget;
